import { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const NavigationBar = () => {
  const [active, setActive] = useState("home");
  const { logout, firstName } = useAuth();

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>Mijn SKB</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto"
              activeKey={active}
              onSelect={(selectedKey: any) => setActive(selectedKey)}
            >
              <Nav.Link as={Link} to="/" eventKey="home">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/about" eventKey="about">
                About
              </Nav.Link>
              <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
            </Nav>
            {firstName && (
              <Nav>
                <NavDropdown
                  title={`Hello, ${firstName}`}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
