import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContextType from '../models/interfaces/AuthContextType';
import SecureStorage from '../helpers/SecureStorage';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      setFirstName(SecureStorage.getItem('firstName'));
      setLastName(SecureStorage.getItem('lastName'));
    }
    setLoading(false);
  }, []);

  const login = (token: string) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setFirstName(SecureStorage.getItem('firstName'));
    setLastName(SecureStorage.getItem('lastName'));
    navigate('/');
  };

  const logout = () => {
    localStorage.removeItem('token');
    SecureStorage.logout();
    setIsAuthenticated(false);
    navigate('/auth/login');
  };

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, firstName, lastName }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}