import CryptoJS from "crypto-js";

const SECRET_KEY = "SO)!UR!)(J!F=1f=0IK! _K! _-secret-key"; // Replace with your own secret key

class SecureStorage {
  static setItem(key: string, value: string) {
    const encryptedValue = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
    localStorage.setItem(key, encryptedValue);
  }

  static getItem(key: string): string | null {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;

    const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    console.log("Decrypted Value:", decryptedValue);
    return decryptedValue;
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static logout() {
    localStorage.clear();
  }
}

export default SecureStorage;
