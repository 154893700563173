import { Outlet } from "react-router-dom";
import NavigationBar from "../components/ui/NavigationBar";
import { AuthProvider } from "../contexts/AuthContext";

const MainLayout = () => {
  return (
    <>
        <NavigationBar />
        <div className="container">
          <Outlet />
        </div>
    </>
  );
};

export default MainLayout;
