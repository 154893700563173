import axios from "axios";
import LoginDTO from "../models/DTOs/LoginDTO";
import RegisterDTO from "../models/DTOs/RegisterDTO";
import SecureStorage from "../helpers/SecureStorage";

class AuthService {
  static async register(data: RegisterDTO) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/Auth/register`,
      data
    );
    return response.data;
  }

  static async login(data: LoginDTO) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/Auth/login`,
      data
    );

    const { token, firstName, lastName } = response.data;

    // Store first and last name securely
    SecureStorage.setItem("firstName", firstName);
    SecureStorage.setItem("lastName", lastName);

    return { token: response.data.token };
  }
}

export default AuthService;
