import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import HomePage from "../pages/HomePage";
import AuthLayout from "../layouts/AuthLayout";
import RegisterPage from "../pages/auth/RegisterPage";
import LoginPage from "../pages/auth/LoginPage";
import AboutPage from "../pages/AboutPage";
import ProtectedRoute from "../components/ProtectedRoute";
import { AuthProvider } from "../contexts/AuthContext";
import LoggedinRoute from "../components/LoggedInRoute";

const Routes = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthProvider>
          <ProtectedRoute />
        </AuthProvider>
      ),
      children: [
        {
          path: "/",
          element: <MainLayout />,
          children: [
            {
              path: "/",
              element: <HomePage />,
            },
            {
              path: "about",
              element: <AboutPage />,
            },
          ],
        },
      ],
    },
    {
      path: "/auth",
      element: (
        <AuthProvider>
          <LoggedinRoute />
        </AuthProvider>
      ),
      children: [
        {
          path: "",
          element: <AuthLayout />,
          children: [
            {
              path: "register",
              element: <RegisterPage />,
            },
            {
              path: "login",
              element: <LoginPage />,
            },
          ],
        },
      ],
    },
  ]);
};

export default Routes;
